module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-native-web/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cricket Vision","short_name":"cricketvision","start_url":"/","background_color":"#fff","theme_color":"rgb(39, 169, 79)","display":"standalone","icon":"src/assets/icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
